import { render, staticRenderFns } from "./to-topper-component.vue?vue&type=template&id=52d9f146&scoped=true"
import script from "./to-topper-component.vue?vue&type=script&lang=js"
export * from "./to-topper-component.vue?vue&type=script&lang=js"
import style0 from "./to-topper-component.vue?vue&type=style&index=0&id=52d9f146&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52d9f146",
  null
  
)

export default component.exports